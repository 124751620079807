const makeFunction = (callback) => {
  return (t) => {
    const isNegative = t < 0;

    const value = callback(Math.abs(t));
    if (isNegative && value > 0) {
      return value * -1;
    }
    else {
      return value;
    }
  };
};

export default {
  linear: makeFunction((t) => {
    return t;
  }),
  Quadratic: {
    easeIn: makeFunction((t) => {
      return t * t;
    }),
    easeOut: makeFunction((t) => {
      return t * (2 - t);
    }),
    easeInOut: makeFunction((t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    })
  },
  Cubic: {
    easeIn: makeFunction((t) => {
      return t * t * t;
    }),
    easeOut: makeFunction((t) => {
      return (--t) * t * t + 1;
    }),
    easeInOut: makeFunction((t) => {
      return t <.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    })
  },
  Quart: {
    easeIn: makeFunction((t) => {
      return t*t*t*t;
    }),
    easeOut: makeFunction((t) => {
      return 1-(--t)*t*t*t;
    }),
    easeInOut: makeFunction((t) => {
      return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t;
    })
  },
  Quint: {
    easeIn: makeFunction((t) => {
      return t * t * t * t * t;
    }),
    easeOut: makeFunction((t) => {
      return 1 + (--t) * t * t * t * t;
    }),
    easeInOut: makeFunction((t) => {
      return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    })
  }
};
