import { isFunction, isPresent, isNumber } from '@tight/is-type';
import { camelize } from '@tight/pistons-inflection';

import coalesce from '$coalesce';

export default class Control {
  get path() {
    return coalesce(
      this.el.getAttribute('name'),
      this.el.getAttribute('data-name')
    )
      .replace(']', '')
      .split('[')
      .map(s => camelize(s));
  }

  get name() {
    return this.path[this.path.length - 1];
  }

  get value() {
    const value = coalesce(this.el.value, '');

    if (this.isEmail) {
      return value.trim();
    }
    else {
      return value;
    }
  }

  set value(newValue) {
    this.el.value = newValue;
  }

  get length() {
    return this.value.length;
  }

  get isEmpty() {
    return this.length === 0;
  }

  get tooShort() {
    if (!this.hasMinLength) {
      return false;
    }
    if (this.isRequired && this.length < this.minLength) {
      return true;
    }
    if (!this.isRequired && !this.isEmpty && this.length < this.minLength) {
      return true;
    }

    return false;
  }

  get tooLong() {
    if (!this.hasMaxLength) {
      return false;
    }

    return this.length > this.maxLength;
  }

  get isBlank() {
    return this.isRequired && this.isEmpty;
  }

  get isValid() {
    return this.el.checkValidity();
  }

  get validationMessage() {
    if (this.isValid) {
      return null;
    }
    else if (this.isEmail) {
      return 'invalidEmail';
    }
    else if (this.tooShort) {
      return 'tooShort';
    }
    else if (this.tooLong) {
      return 'tooShort';
    }
    else if (this.isBlank) {
      return 'blank';
    }
    else {
      return 'invalid';
    }
  }

  get validationMessages() {
    return this.validationMessage;
  }

  constructor(el) {
    this.el = el;
    this.parent = null;

    this.addEventListeners();
  }

  reset() {
    this.value = '';
  }

  didChange() {
    if (!isPresent(this.parent)) {
      console.warn('Form.Control: parent is not present');
      return;
    }
    if (!isFunction(this.parent.didChange)) {
      console.warn('Form.Control: parent.didChange is not a function');
      return;
    }

    this.parent.didChange(this);
  }

  addEventListeners() {
    this.el.addEventListener('input', () => {
      this.didChange();
    });
  }

  get type() {
    return coalesce(this.getAttribute('type'), 'text');
  }

  get isEmail() {
    return this.type === 'email';
  }

  get isPassword() {
    return this.type === 'password';
  }

  get isText() {
    return this.type === 'text';
  }

  get isRequired() {
    return this.getBoolAttribute('required');
  }

  get minLength() {
    return this.getIntAttribute('minlength', null);
  }

  get maxLength() {
    return this.getIntAttribute('maxlength', null);
  }

  get hasMinLength() {
    return isNumber(this.minLength);
  }

  get hasMaxLength() {
    return isNumber(this.maxLength);
  }

  getBoolAttribute(name) {
    return isPresent(this.getAttribute(name));
  }

  getIntAttribute(name, fallback = 0) {
    return coalesce(
      parseInt(this.getAttribute(name)),
      fallback
    );
  }

  getAttribute(name, fallback = null) {
    return coalesce(
      this.el.getAttribute(name),
      fallback
    );
  }
}
