import {
  isString,
  isPresent,
} from '@tight/is-type';
import { Rect } from '$geometry';

const toHex = (colorString) => {
  const rgb = colorString.split('(')[1]
    .split(')')[0]
    .split(',');

  return '#' + rgb
    .map((x) => {
      x = parseInt(x).toString(16);
      return (x.length === 1) ? '0' + x : x;
    })
    .join('');

};

const findColor = (...colors) => {
  return colors.find((color) => {
    return isString(color) && color.length > 0;
  });
};

const hasBackgroundColor = (el) => {
  return getBackgroundColor(el) !== '#00000000';
};

const getBackgroundColor = (el) => {
  const style = window.getComputedStyle(el);
  return toHex(style.backgroundColor);
};

const setThemeColor = (() => {
  let lastHexColor = null;

  return (hexColor) => {
    if (lastHexColor === hexColor) {
      return;
    }

    const tag = document.head.children.namedItem('theme-color');
    tag.setAttribute('content', hexColor);
    lastHexColor = hexColor;
  };
})();

document.addEventListener('DOMContentLoaded', () => {
  const themeColorTag = document.head.children.namedItem('theme-color');
  const themeColor = themeColorTag.content;
  const colorElements = [...document.querySelectorAll('[data-theme-color-el]')];

  if (colorElements.length === 0) {
    return;
  }

  const updateThemeColor = () => {
    const bodyRect = Rect.fromEl(document.body);
    const viewport = Rect.fromViewport();

    if (viewport.maxY > bodyRect.height) {
      return;
    }

    const element = colorElements.find((el) => {
      const rect = Rect.fromEl(el)
        .move(0, -viewport.y);

      return rect.y <= 0 && rect.maxY > 0 && hasBackgroundColor(el);
    });

    if (isPresent(element)) {
      setThemeColor(getBackgroundColor(element));
    }
    else if (viewport.y < 0 ) {
      const color = findColor(
        themeColor,
        getBackgroundColor(document.body)
      );

      setThemeColor(color);
    }
    else {
      const color = getBackgroundColor(document.body);

      setThemeColor(color);
    }
  };

  window.addEventListener('scroll', updateThemeColor);
  updateThemeColor();
});
