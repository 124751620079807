import { isObject, isPresent } from '@tight/is-type';
import { underscoreKeys } from '@tight/pistons-params';

export default (params) => {
  if (!isObject(params)) {
    params = {};
  }

  const param = document.querySelector('meta[name="csrf-param"]');
  const value = document.querySelector('meta[name="csrf-token"]');
  if (isPresent(param) && isPresent(value)) {
    params[param.getAttribute('content')] = value.getAttribute('content');
  }

  return underscoreKeys(params);
};
