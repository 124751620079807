import { isPresent, isArray } from '@tight/is-type';

import coalesce from '$coalesce';

import Slide from './slide';
import DragHandler from './drag_handler';
import keyboardHandler from './keyboard_handler';
import transition from './transition';
import transitionDistance from './transition_distance';

import {
  findPath,
  lerp,
  clamp,
} from './util';

export default class Carousel {
  get $viewport() {
    return this.el.querySelector('.carousel-viewport');
  }

  get $list() {
    return this.el.querySelector('ol');
  }

  get $currentSlides() {
    return Array.prototype.slice.call(this.$list.querySelectorAll('li'));
  }

  get numberOfSlides() {
    return this.slides.length;
  }

  get slide() {
    return Math.round(this.progress);
  }

  set slide(slide) {
    this.transitionToSlide(slide);
  }

  get progress() {
    return isPresent(this._progress) ? this._progress : 0;
  }

  set progress(value) {
    value = clamp(this.minProgress, this.maxProgress, value);
    const oldValue = this._progress;
    this._progress = value;

    if (oldValue !== value) {
      this.progressDidChange();
    }

    this.dispatchDelta(
      coalesce(oldValue, 0),
      value
    );
  }

  get minProgress() {
    if (!this.loops) {
      return 0;
    }
    return -Infinity;
  }

  get maxProgress() {
    if (!this.loops) {
      return this.numberOfSlides - 1;
    }
    return Infinity;
  }

  get minVisualProgress() {
    return this.getOption('minVisualProgress', this.minProgress);
  }

  set minVisualProgress(value) {
    this.setOption('minVisualProgress', value);
  }

  get maxVisualProgress() {
    return this.getOption('maxVisualProgress', this.maxProgress);
  }

  set maxVisualProgress(value) {
    this.setOption('maxVisualProgress', value);
  }

  get isTransitioning() {
    return !!this._isTransitioning;
  }

  set isTransitioning(value) {
    if (this._isTransitioning === value) {
      console.log('isTransitioning');
    }
    this._isTransitioning = value;
    this.$viewport.classList.toggle('is-transitioning', value);

    if (isPresent(this.dragHandler)) {
      this.dragHandler.isEnabled = !value;
    }

    if (value) {
      this.publish('start', {
        slide: this.slide
      });
    }
    else {
      this.publish('end', {
        slide: this.slide
      });
    }
  }

  get userInteractionEnabled() {
    return this.getOption('userInteractionEnabled', true);
  }

  set userInteractionEnabled(value) {
    this.setOption('userInteractionEnabled', value);
  }

  get userInteractionDisabled() {
    return !this.userInteractionEnabled;
  }

  get loops() {
    return this.getOption('loops', true);
  }

  set loops(value) {
    this.setOption('loops', value);
  }

  get duration() {
    return this.getOption('duration', 375);
  }

  set duration(value) {
    this.setOption('duration', value);
  }

  constructor(el, options = {}) {
    this.options = options;
    this.watchers = {};

    this.el = el;
    this.slides = this.$currentSlides.map((slide) => {
      return new Slide(slide);
    });

    this.addEventListeners();
    this.progress = 0;
  }

  addEventListeners() {
    if (this.userInteractionDisabled) {
      return;
    }

    this.addChangeSlideHandlers();
    this.addDragHandlers();
    this.addKeyboardHandlers();
  }

  addChangeSlideHandlers() {
    this.el.querySelectorAll('[data-change-slide]').forEach((el) => {
      el.addEventListener('click', (e) => {
        if (this.isTransitioning) {
          return;
        }

        let delta = 0;
        if (isPresent(e.currentTarget.dataset.delta)) {
          delta = parseInt(e.currentTarget.dataset.delta);
        }
        else if (isPresent(e.currentTarget.dataset.index)) {
          const index = parseInt(e.currentTarget.dataset.index);

          delta = findPath(
            this.clampedProgress(),
            index,
            this.slides.length,
            {
              loops: this.loops
            }
          );
        }
        else {
          delta = findPath(
            this.clampedProgress(),
            this.randomProgress(),
            this.slides.length,
            {
              loops: this.loops
            }
          );
        }

        if (delta !== 0) {
          this.transitionToDelta(delta);
        }
      });
    });
  }

  addDragHandlers() {
    this.dragHandler = new DragHandler(this.$viewport);
    this.dragHandler.subscribe('drag', (delta) => {
      const scale = this.$viewport.getBoundingClientRect().width /
        this.$currentSlides[0].getBoundingClientRect().width;
      this.progress += delta * scale;
    });

    this.dragHandler.subscribe('start', () => {
      this.startProgress = this.progress;
      this.el.classList.add('is-grabbing');
      this.publish('dragStart', {
        slide: this.slide
      });
    });

    this.dragHandler.subscribe('end', (duration) => {
      this.el.classList.remove('is-grabbing');

      const distance = Math.abs(this.progress - this.startProgress);
      if (distance === 0) {
        return;
      }

      const swipeTime = 300;
      const offset = 1 - Math.min(1, Math.min(duration, swipeTime) / swipeTime);
      const threshold = 0.3;

      if (distance >= threshold - (threshold * offset)) {
        const direction = this.progress - this.startProgress > 0 ? 1 : -1;
        let delta = Math.ceil(Math.abs(this.progress - this.startProgress));

        this.transitionToOffset(this.startProgress + (delta * direction));
      }
      else {
        this.transitionToOffset(this.startProgress);
      }
    });
  }

  addKeyboardHandlers() {
    keyboardHandler.subscribe(this.el)
      .onMove((delta) => {
        if (this.isTransitioning) {
          return;
        }

        this.transitionToDelta(delta);
      });
  }

  transitionToSlide(slide) {
    const delta = findPath(
      this.clampedProgress(),
      slide,
      this.slides.length,
      {
        loops: this.loops
      }
    );

    if (delta !== 0) {
      this.transitionToDelta(delta);
    }
  }

  canTransitionToDelta(delta) {
    if (this.loops) {
      return true;
    }
    else if (this.progress + delta < 0) {
      return false;
    }
    else if (this.progress + delta >= this.numberOfSlides) {
      return false;
    }
    else {
      return true;
    }
  }

  transitionToDelta(delta) {
    const from = this.progress;
    const to = from + delta;

    this.transitionToOffset(to);
  }

  transitionToOffset(to) {
    if (to < this.minProgress) {
      return Promise.resolve();
    }
    if (to > this.maxProgress) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      if (this.isTransitioning) {
        resolve();
        return;
      }

      this.isTransitioning = true;

      const from = this.progress;
      const distance = Math.abs(from - to);
      const duration = Math.min(
        this.duration,
        this.duration * transitionDistance(to, from)
      );

      let options  = {
        duration,
        easingFunction: distance < 1 ? 'Cubic.easeOut' : null
      };

      transition(from, to, options)
        .progress((progress) => {
          this.progress = progress;
        })
        .then(() => {
          resolve();
          this.isTransitioning = false;
        });
    });

  }

  clampedProgress() {
    let progress = this.progress % this.numberOfSlides;
    if (progress >= (this.numberOfSlides -  0.5)) {
      progress -= this.numberOfSlides;
    }
    else if (progress < -0.5) {
      progress = (this.numberOfSlides) + progress;
    }

    return progress;
  }

  progressDidChange() {
    const progress = this.clampedProgress();

    this.updateContainerTransform(progress);
    this.updateSlideTransforms(progress);
    this.publish('progress', {
      progress,
      slideProgress: this.getSlideProgress(progress)
    });
  }

  updateContainerTransform(clampedProgress) {
    clampedProgress = clamp(
      this.minVisualProgress,
      this.maxVisualProgress,
      clampedProgress
    );

    this.$list.style = [
      `transform: translate3d(${clampedProgress * -100}%, 0, 0);`
    ].join('');
  }

  updateSlideTransforms(clampedProgress) {
    clampedProgress = Math.min(this.maxProgress - 1, clampedProgress)
    const primarySlide = this.nextSlide(Math.round(clampedProgress), 0);
    const slidesBefore = Math.round((this.numberOfSlides - 1) / 2);
    const slidesAfter = (this.numberOfSlides - 1) - slidesBefore;

    let offsets = {};

    for (let i = 1; i <= slidesBefore; i++) {
      let index = this.nextSlide(i * -1, primarySlide);

      if (index > primarySlide) {
        const itemsBefore = Object.keys(offsets)
          .filter(i => offsets[i] < 0)
          .length;

        offsets[index] = (itemsBefore + 1) * -1;
      }
      else {
        offsets[index] = index;
      }
    }

    for (let i = 0; i <= slidesAfter; i++) {
      let index = this.nextSlide(i, primarySlide);

      if (index < primarySlide) {
        const slidesAfter = (this.numberOfSlides - primarySlide);
        offsets[index] = primarySlide + slidesAfter + index;
      }
      else {
        offsets[index] = index;
      }
    }

    this.slides.forEach((slide, i) => {
      slide.position = offsets[i] || 0;
    });
  }

  getSlideProgress() {
    const clampedProgress = this.clampedProgress();
    const lastIndex = this.numberOfSlides -  1;

    return this.slides.map((slide) => {
      // The first and last slides need special handling when the carousel wraps
      // between the first and last slide
      //
      // First slide when moving from 0 to LAST
      if (slide.index === 0 && clampedProgress < 0) {
        return 1 + clampedProgress;
      }
      // First slide when moving from LAST to 0
      else if (slide.index === 0 && clampedProgress > lastIndex) {
        return clampedProgress - lastIndex;
      }
      // Last slide when moving from 0 to LAST
      else if (slide.index === lastIndex && clampedProgress < 0) {
        return Math.abs(clampedProgress);
      }
      // Last slide when moving from LAST to 0
      else if (slide.index === lastIndex && clampedProgress > lastIndex) {
        return 1 - (clampedProgress - lastIndex);
      }

      const minProgress = slide.index - 1;
      const maxProgress = (slide.index + 1);
      if (clampedProgress > minProgress && clampedProgress < slide.index) {
        return clampedProgress - Math.max(0, slide.index - 1);
      }
      else if (clampedProgress > slide.index && clampedProgress < maxProgress) {
        return 1 - (clampedProgress - slide.index);
      }
      else {
        return slide.index === clampedProgress ? 1 : 0;
      }
    });
  }

  nextSlide(delta, relativeTo) {
    relativeTo = isPresent(relativeTo) ? relativeTo : this.currentIndex;
    const next = relativeTo + delta;

    if (delta === 0) {
      return relativeTo;
    }
    else if (next < 0) {
      return this.slides.length - (Math.abs(delta) - relativeTo);
    }
    else if (next >= this.slides.length) {
      return next % this.slides.length;
    }
    else {
      return next;
    }
  }

  randomProgress() {
    const next = Math.round(Math.random() * (this.slides.length - 1));

    if (next === this.clampedProgress()) {
      return this.randomProgress();
    }
    else {
      return next;
    }
  }

  dispatchDelta(oldValue, value) {
    const scale = this.$viewport.getBoundingClientRect().width /
        this.$currentSlides[0].getBoundingClientRect().width;
    const delta = Math.abs(oldValue - value) / scale;

    if (delta > 0 && delta < 1) {
      this.publish('delta', {
        delta: (oldValue - value) / scale
      });
    }
  }

  on(property, handler) {
    if (!isArray(this.watchers[property])) {
      this.watchers[property] = [];
    }

    this.watchers[property].push(handler);
  }

  publish(property, ...args) {
    if (!isArray(this.watchers[property])) {
      return;
    }

    this.watchers[property].forEach((handler) => {
      handler.apply(this, args);
    });
  }

  getOption(option, fallback = null) {
    return coalesce(this.options[option], fallback);
  }

  setOption(option, value) {
    this.options[option] = value;
  }
}
