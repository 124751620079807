import { trackSignUp } from '$public/services/tracking';
import { execute as executeRecaptcha } from '$public/services/recaptcha';

import Api from '$public/api';
import Form from '$public/components/form';

export default {
  initialize(el) {
    const form = Form.initialize(el);

    form.on('submit', () => {
      form.isLoading = true;

      executeRecaptcha('signup')
        .then((recaptchaToken) => {
          return Api.post('/signup', {
            ...form.value,
            recaptchaToken
          });
        })
        .then(() => {
          trackSignUp();
          window.location = '/';
        })
        .catch(() => {
          form.showAlert({
            type: 'error',
            message: 'Something went wrong. Please try again'
          });
        })
        .finally(() => {
          form.isLoading = false;
        });
    });
  }
};
