import { isPresent, isNumber, isString } from '@tight/is-type';

import Carousel from '$carousel';
import transition from '$carousel/transition';

import { attachIndicators } from '$public/components/carousel_indicators';
import { attachControls } from '$public/components/carousel_controls';

export default {
  initialize(el) {
    const carouselEl = el.querySelector('.carousel');
    const carousel = new Carousel(carouselEl);
    carousel.loops = false;
    carousel.maxVisualProgress = carousel.maxProgress - 1;
    carousel.duration = 350;


    attachIndicators(carousel, {
      el: el.querySelector('[data-carousel-indicators]')
    });
    attachControls(carousel, {
      el: el.querySelector('[data-carousel-controls]')
    });

    let scribblesHidden = false;

    carousel.on('end', ({ slide }) => {
      showScribble(slide).then(() => {
        scribblesHidden = false;
      });
    });

    carousel.on('progress', ({ progress }) => {
      const printProgress = Math.max(0, progress - (carousel.maxProgress - 1));
      el.style.setProperty('--print-progress', printProgress);

      if (!scribblesHidden) {
        el.querySelectorAll('.scribble').forEach((scribble) => {
          hideScribble(scribble);
        });
        scribblesHidden = true;
      }
    });

    const getScribble = (slide) => {
      return el.querySelector(`.scribble[data-slide="${slide}"]`);
    };

    const showScribble = (scribble, animated = true) => {
      return setScribbleVisibility(scribble, true, animated);
    };

    const hideScribble = (scribble, animated = true) => {
      return setScribbleVisibility(scribble, false, animated);
    };

    const setScribbleVisibility = (scribble, visible, animated = true) => {
      if (isNumber(scribble) || isString(scribble)) {
        scribble = getScribble(scribble);
      }
      if (!isPresent(scribble)) {
        return Promise.resolve();
      }

      const from = [parseFloat(scribble.style.opacity), 0].find((opacity) => {
        return !isNaN(opacity);
      });
      const to = visible ? 1 : 0;

      if (animated) {
        return transition(from, to, { duration: 250 })
          .progress((progress) => {
            scribble.style.opacity = progress;
          });
      }
      else {
        scribble.style.opacity = to;
        return Promise.resolve();
      }
    };

    showScribble(0, false);
  }
};

