import coalesce from '$coalesce';

const CONTROLS_SELECTOR = '[data-carousel-control]';

export const attachControls = (carousel, options = {}) => {
  const controlsEl = coalesce(
    options.el,
    carousel.el.querySelector('[data-carousel-controls]')
  );
  const controls = toArray(controlsEl.querySelectorAll(CONTROLS_SELECTOR));

  Object.values(controls).forEach((button) => {
    button.addEventListener('click', () => {
      carousel.transitionToDelta(getDelta(button));
    });
  });

  const toggleControls = () => {
    controls.forEach((control) => {
      const delta = getDelta(control);
      const enabled = carousel.canTransitionToDelta(delta);

      control.toggleAttribute('disabled', !enabled);
    });
  };

  carousel.on('end', toggleControls);
  toggleControls();
};

const getDelta = (control) => {
  switch (control.dataset.carouselControl) {
    case 'next':
      return 1;
    case 'prev':
      return -1;
    default:
      return 0;
  }
};

const toArray = (object) => {
  return Array.prototype.slice.call(object);
};
