import { dasherize } from '@tight/pistons-inflection';
import { isPresent } from '@tight/is-type';

import mappedIcons from '$icons/sunrise/mapped.json';

const icons = {
  ...mappedIcons
};

export default {
  render(name) {
    const container = document.createElement('div');
    container.classList.add('icon');
    container.classList.add(`icon-${dasherize(name).replace(/[./]+/g, '-')}`);

    if (isPresent(icons[name])) {
      container.innerHTML = icons[name];
    }
    else {
      console.warn(`Unknown icon '${name}'`);
    }

    return container;
  }
};
