import { isFunction, isPresent } from '@tight/is-type';
import walk from '@tight/pistons-walk';

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';

const Icons = Object.freeze({
  ...['normal'].reduce((icons, state) => {
    return {
      ...icons,
      [state]: ['dark', 'light'].reduce((variants, mode) => {
        return {
          ...variants,
          [mode]: ['ico'].reduce((formats, format) => {
            return {
              ...formats,
              [format]: require(`$favicons/${state}.${mode}.${format}`)
            };
          }, {})
        };
      }, {})
    };
  }, {})
});

const updateFavicon = () => {
  const icoTag = document.querySelector('link[type="image/x-icon"]');
  const icoUrl = resolveFaviconUrl('ico');

  if (isPresent(icoTag) && isPresent(icoUrl)) {
    icoTag.setAttribute('href', icoUrl);
  }
};

const resolveFaviconUrl = (format) => {
  return walk(Icons, `normal.${resolveColorScheme()}.${format}`);
};

const resolveColorScheme = () => {
  if (
    isFunction(window.matchMedia) &&
    window.matchMedia(COLOR_SCHEME_QUERY).matches
  ) {
    return 'dark';
  }

  return 'light';
};

updateFavicon();
window.matchMedia(COLOR_SCHEME_QUERY)
  .addEventListener('change', () => {
    updateFavicon();
  });
