import { isPresent, isFunction } from '@tight/is-type';

export const trackSignUp = () => {
  trackEvent('EQQDVOTR');
};
export const trackSignIn = () => {
  trackEvent('4EOI9X7T');
};
export const trackActivateUser = () => {
  trackEvent('K9JWHKSJ');
};

export const trackEvent = (id, value = 0) => {
  if (
    !isPresent(window.fathom) ||
    !isFunction(window.fathom.trackGoal)
  ) {
    return;
  }

  window.fathom.trackGoal(id, value);
};
