export const findPath = (from, to, slides, options = {}) => {
  const {
    loops = false
  } = options;

  if (loops) {
    return findLoopingPath(from, to, slides, options);
  }
  else {
    return to - from;
  }
};

const findLoopingPath = (from, to, slides) => {
  let left = 0;
  let right = 0;

  if (from > to) {
    left = to - from;
    right = (slides + to) - from;
  }
  else {
    left = to - (from + slides);
    right = to - from;
  }

  return Math.abs(left) < right ? left : right;
};

export const lerp = (start, end, amount) => {
  return start + ((end - start) * amount);
};

export const clamp = (min, max, value) => {
  return Math.max(min, Math.min(max, value));
};
