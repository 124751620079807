import { isPresent, isFunction } from '@tight/is-type';
import { underscore } from '@tight/pistons-inflection';

const isNestedComponent = (el)  => {
  if (!isPresent(el)) {
    return false;
  }
  if (!isPresent(el.parentNode)) {
    return false;
  }
  if (!isPresent(el.parentNode.dataset)) {
    return isNestedComponent(el.parentNode);
  }
  if (isPresent(el.parentNode.dataset.component)) {
    return true;
  }

  return isNestedComponent(el.parentNode);
};

export default {
  initialize(el) {
    el.querySelectorAll('[data-component]').forEach((el) => {
      if (isNestedComponent(el)) {
        return;
      }

      const name = underscore(el.dataset.component);
      const { default: component } = require(`../${name}`);

      if (isFunction(component.initialize)) {
        component.initialize(el);
      }
      else {
        console.warn(`Unable to initialize component '${name}'`);
      }
    });
  }
};
