import { isPresent, isNumber } from '@tight/is-type';

export default (...args) => {
  return args.find((arg) => {
    if (isNumber(arg) && isNaN(arg)) {
      return false;
    }


    return isPresent(arg);
  });
};
