import Carousel from '$carousel';

import { attachIndicators } from '$public/components/carousel_indicators';
import { attachControls } from '$public/components/carousel_controls';

export default {
  initialize(el) {
    const carouselEl = el.querySelector('.testimonials');
    const carousel = new Carousel(carouselEl);

    attachIndicators(carousel);
    attachControls(carousel);
  }
};
