import { isFunction } from '@tight/is-type';

const initializers = require.context('./initializers', true, /\.js$/);
initializers.keys().forEach((k) => {
  const initializer = initializers(k);
  if (isFunction(initializer)) {
    initializer();
  }
  else if (isFunction(initializer.default)) {
    initializer.default();
  }
});
