import { isPresent } from '@tight/is-type';

export default class Slide {
  get position() {
    return isPresent(this._position) ? this._position : 0;
  }

  set position(value) {
    const oldValue = this._position;
    this._position = value;

    if (oldValue !== value) {
      this.updateTransform();
    }
  }

  get index() {
    return this.slides.findIndex((el) => {
      return el.isSameNode(this.el);
    });
  }

  get slides() {
    return Array.prototype.slice.call(this.el.parentNode.children);
  }

  get numberOfSlides() {
    return this.slides.length;
  }

  constructor(el) {
    this.el = el;
  }

  updateTransform() {
    const translation = -this.index + this.position;
    this.el.style = `transform: translateX(${translation * 100}%);`;
  }
}
