import { isObject } from '@tight/is-type';

class RequestLog {
  static start(method, path, params) {
    const log =  new RequestLog(method, path, params);
    log.start();
    return log;
  }

  get pathWithQuery() {
    if (!this.hasQueryString) {
      return this.path;
    }

    return `${this.path}?${this.queryString}`;
  }

  get hasQueryString() {
    return isObject(this.params) && Object.keys(this.params).length > 0;
  }

  get queryString() {
    if (!this.hasQueryString) {
      return '';
    }

    let queryString = [];
    Object.keys(this.params).forEach((key) => {
      queryString.push(`${key}=${this.params[key]}`);
    });
    return queryString.join('&');
  }

  constructor(method, path, params) {
    this.method = method;
    this.path = path;
    this.params = params;
  }

  start() {
    console.info(this.method, this.pathWithQuery);
  }

  end() {
  }
}

export default RequestLog;
