import coalesce from '$coalesce';

export const attachIndicators = (carousel, options = {}) => {
  const indicatorsEl = coalesce(
    options.el,
    carousel.el.querySelector('[data-carousel-indicators]')
  );

  const update = (progress) => {
    const indicators = indicatorsEl.querySelectorAll('li');
    progress.forEach((progress, i) => {
      indicators[i].style.setProperty('--progress', progress);
    });
  };

  const geListRect = () => {
    const minWidth = 160;
    const listEl = indicatorsEl.querySelector('ol');
    const bounds = listEl.getBoundingClientRect();

    const center = bounds.x + (bounds.width / 2);
    let width = bounds.width;

    if (width < minWidth) {
      width = minWidth;
    }

    let x = (center - (width / 2)) + window.scrollX;
    const y = bounds.y + window.scrollY;

    return {
      x,
      maxX: x + width,
      y,
      maxY: y + bounds.height,
      width,
      height: bounds.height
    };
  };

  const getPos = (e) => {
    return {
      x: e.clientX + window.scrollX,
      y: e.clientY + window.scrollY,
    };
  };

  indicatorsEl.addEventListener('click', (e) => {
    const rect = geListRect();
    const pos = getPos(e);

    if (
      !(pos.x >= rect.x && pos.x <= rect.maxX) ||
      !(pos.y >= rect.y && pos.y <= rect.maxY)
    ) {
      return;
    }

    if (pos.x < (rect.x + (rect.width / 2))) {
      carousel.transitionToDelta(-1);
    }
    else {
      carousel.transitionToDelta(1);
    }
  });

  carousel.on('progress', ({ slideProgress }) => {
    update(slideProgress);
  });

  update(carousel.getSlideProgress());
};
