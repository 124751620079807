const isPx = (value) => {
  return /^[^a-zA-Z]+px$/.test(value);
};

const toPx = (el, value) => {
  if (isPx(value)) {
    return parseFloat(value);
  }

  console.warn(`Unable to convert ${value} to px`);
  return 0;
};

export default class Rect {
  get maxX() {
    return this.x + this.width;
  }

  get maxY() {
    return this.y + this.height;
  }

  get description() {
    const properties = [
      'x',
      'y',
      'maxX',
      'maxY',
      'width',
      'height',
    ].reduce((fullDescription, property) => {
      const description = `${property}: ${this[property]}`;

      if (fullDescription === null) {
        return description;
      }

      return `${fullDescription}, ${description}`;
    }, null);
    return `{ ${properties} }`;
  }

  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  static zero() {
    return new Rect(0, 0, 0, 0);
  }

  static fromEl(el) {
    if (!el) {
      return Rect.zero();
    }

    const rect = el.getBoundingClientRect();
    const { x, y } = rect;
    let { width, height } = rect;

    const style = window.getComputedStyle(el);

    if (style.height !== 'auto') {
      height = toPx(el, style.height);

      if (style.boxSizing !== 'border-box') {
        height += toPx(el, style.paddingTop) + toPx(el, style.paddingBottom);
      }
    }

    if (style.width !== 'auto') {
      width = toPx(el, style.width);

      if (style.boxSizing !== 'border-box') {
        width += toPx(el, style.paddingLeft) + toPx(el, style.paddingRight);
      }
    }

    return new Rect(
      x + window.scrollX,
      y + window.scrollY,
      width,
      height
    );
  }

  static fromBody() {
    return Rect.fromEl(window.document.body);
  }

  static fromViewport() {
    return new Rect(
      window.scrollX,
      window.scrollY,
      window.innerWidth,
      window.innerHeight
    );
  }

  move(dx, dy) {
    return new Rect(
      this.x + dx,
      this.y + dy,
      this.width,
      this.height
    );
  }

  resize(dw, dh) {
    return new Rect(
      this.x,
      this.y,
      this.width + dw,
      this.height + dh
    );
  }

  scale(dx, dy = null) {
    if (dy === null) {
      return this.scale(dx, dx);
    }

    return this
      .move(dx / -2, dy / -2)
      .resize(dx, dy);
  }

  contains(x, y) {
    return (x >= this.x && x <= this.maxX) &&
      (y >= this.y && y <= this.maxY);
  }

  isIntersecting(other) {
    // this is to the right of other
    if (this.x > other.maxX) {
      return false;
    }

    // other is to the right of this
    if (other.maxX < this.x) {
      return false;
    }

    // this is below other
    if (this.y > other.maxY) {
      return false;
    }

    // other is below this
    if (other.y > this.maxY) {
      return false;
    }

    return true;
  }

  intersection(other) {
    if (!this.isIntersecting(other)) {
      return Rect.zero();
    }

    return new Rect(
      Math.max(this.x, other.x),
      Math.max(this.y, other.y),
      Math.min(this.maxX, other.maxX) - Math.max(this.x, other.x),
      Math.min(this.maxY, other.maxY) - Math.max(this.y, other.y)
    );
  }
}
