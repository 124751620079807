import { isString } from '@tight/is-type';

const messages = {
  invalidEmail: 'is not valid',
  tooShort: 'is too short',
  tooLong: 'is too long',
  blank: 'can\'t be blank',
  invalid: 'is invalid'
};

export const flattenMessages = (object) => {
  return Object.entries(object)
    .reduce((messages, [field, value]) => {
      if (isString(value)) {
        return {
          ...messages,
          [field]: value
        };
      }
      else {
        return {
          ...messages,
          ...flattenMessages(value)
        };
      }
    }, {});
};

export const formatValidationMessages = (messages) => {
  return Object.entries(flattenMessages(messages)).map(([field, message]) => {
    return formatValidationMessage(field, message);
  });
};

const formatValidationMessage = (field, error) => {
  return `${formatFieldName(field)} ${downcaseFirst(errorMessage(error))}`;
};

const formatFieldName = (name) => {
  return upcaseFirst(name);
};

const errorMessage = (error) => {
  return messages[error];
};

const upcaseFirst = (string) => {
  return string[0].toUpperCase() + string.substring(1);
};

const downcaseFirst = (string) => {
  return string[0].toLowerCase() + string.substring(1);
};
