import { isPresent } from '@tight/is-type';

export const execute = ((action) => {
  return new Promise((resolve) => {
    const { grecaptcha } = window;

    if (!isPresent(grecaptcha)) {
      resolve(null);
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(
        '6LcbLhApAAAAAOmff2E-x2cHss9Wc5qxywdmzmPB', // TODO: Inject from env
        {
          action
        }
      ).then(resolve);
    });
  });
});
