import { isPresent } from '@tight/is-type';

import coalesce from '$coalesce';

export default class Button {
  get isLoading() {
    return this.getState('isLoading', false);
  }

  set isLoading(value) {
    this.setState('isLoading', value);
  }

  get isDisabled() {
    return this.getState('isDisabled', false);
  }

  set isDisabled(value) {
    this.setState('isDisabled', value);
  }

  get isEnabled() {
    return !this.isDisabled;
  }

  set isEnabled(value) {
    this.isDisabled = !value;
  }

  constructor(el) {
    this.el = el;
    this.state = {};
  }

  stateChanged() {
    this.toggleActivityIndicator();
    this.el.classList.toggle('is-loading', this.isLoading);
    this.el.toggleAttribute('disabled', this.isDisabled);
  }

  toggleActivityIndicator() {
    if (this.isLoading) {
      this.createActivityIndicator();
    }
    else {
      this.removeActivityIndicator();
    }
  }

  createActivityIndicator() {
    if (isPresent(this.activityIndicator)) {
      return;
    }

    const el = document.createElement('div');
    el.classList.add('button-activity-indicator');

    const container = document.createElement('div');
    container.classList.add('activity-indicator-horizontal');

    for (let i = 0; i < 3; i++) {
      container.appendChild(document.createElement('i'));
    }

    el.appendChild(container);
    this.el.appendChild(el);
    this.activityIndicator = el;
  }

  removeActivityIndicator() {
    if (!isPresent(this.activityIndicator)) {
      return;
    }

    this.activityIndicator.parentNode.removeChild(this.activityIndicator);
    this.activityIndicator = null;
  }

  getState(attribute, fallback = null) {
    return coalesce(this.state[attribute], fallback);
  }

  setState(attribute, value) {
    if (value === this.getState(attribute)) {
      return;
    }

    this.state[attribute] = value;
    this.stateChanged();
  }
}
