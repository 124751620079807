import coalesce from '$coalesce';

import { trackSignIn } from '$public/services/tracking';
import { execute as executeRecaptcha } from '$public/services/recaptcha';

import Api from '$public/api';
import Form from '$public/components/form';

export default {
  initialize(el) {
    const params = window.location.search
      .replace(/^\?/, '')
      .split('&')
      .reduce((params, segment) => {
        if (segment.length < 3 || !/.=./.test(segment)) {
          return params;
        }

        const [param, value] = segment.split('=');
        return {
          ...params,
          [param]: decodeURIComponent(value)
        };
      }, {});

    const form = Form.initialize(el);

    form.on('submit', () => {
      form.isLoading = true;

      executeRecaptcha('login')
        .then((recaptchaToken) => {
          return Api.post('/login', {
            ...form.value,
            recaptchaToken
          });
        })
        .then(() => {
          trackSignIn();
          window.location = coalesce(params.to, '/');
        })
        .catch((error) => {
          if (error.response.status === 401) {
            form.showAlert({
              type: 'error',
              message: 'Incorrect email address and/or password'
            });
          }
          else {
            form.showAlert({
              type: 'error',
              message: 'Something went wrong. Please try again'
            });
          }
        })
        .finally(() => {
          form.isLoading = false;
        });
    });
  }
};
