import { isArray } from '@tight/is-type';

const ARROW_LEFT = 37;
const ARROW_RIGHT = 39;

class Rect {
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  get minX() {
    return this.x;
  }

  get maxX() {
    return this.x + this.width;
  }

  get minY() {
    return this.y;
  }

  get maxY() {
    return this.y + this.height;
  }

  intersects(other) {
    if (this.minX > other.maxX) {
      return false;
    }
    else if (this.maxX < other.minX) {
      return false;
    }
    else if (this.minY > other.maxY) {
      return false;
    }
    else if (this.maxY < other.minY) {
      return false;
    }
    else {
      return true;
    }
  }
}

class KeyboardSubscriber {
  get isVisible() {
    const offset = {
      top: 50,
      bottom: 100
    };
    const bounds = this.el.getBoundingClientRect();
    const visibleRect = new Rect(
      window.pageXOffset,
      window.pageYOffset,
      window.innerWidth,
      window.innerHeight
    );
    const rect = new Rect(
      bounds.x,
      window.pageYOffset + bounds.y + offset.top,
      bounds.width,
      bounds.height - (offset.top + offset.bottom)
    );

    return rect.intersects(visibleRect);
  }

  constructor(el) {
    this.el = el;
    this.handlers = {};
  }

  onMove(handler) {
    return this.addHandler('move', handler);
  }

  perform(event, ...args) {
    if (!isArray(this.handlers[event])) {
      return;
    }

    this.handlers[event].forEach((handler) => {
      handler.apply(null, args);
    });
  }

  addHandler(event, handler) {
    if (!isArray(this.handlers[event])) {
      this.handlers[event] = [];
    }

    this.handlers[event].push(handler);
    return this;
  }
}

class KeyboardHandler {
  constructor() {
    this.subscribers = [];
    this.eventListenersAdded = false;
  }

  addEventListeners() {
    if (this.eventListenersAdded) {
      return;
    }

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === ARROW_RIGHT) {
        this.move(1);
      }
      else if (e.keyCode === ARROW_LEFT) {
        this.move(-1);
      }
    });

    this.eventListenersAdded = true;
  }

  move(delta) {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.isVisible) {
        subscriber.perform('move', delta);
      }
    });
  }

  subscribe(el) {
    this.subscribers.push(new KeyboardSubscriber(el));
    this.addEventListeners();

    return this.subscribers[this.subscribers.length - 1];
  }
}

export default new KeyboardHandler();
