import { camelize } from '@tight/pistons-inflection';
import { isArray, isFunction, isString, isPresent } from '@tight/is-type';

const isSamePath = (a, b) => {
  return a.length === b.length &&
    a.every((value, i) => {
      return value === b[i];
    });
};

export default class Group {
  get path() {
    if (this.parent === null || !isArray(this.parent.path)) {
      return [this.name].filter(isPresent);
    }

    return [
      ...this.parent.path,
      this.name
    ].filter(isPresent);
  }

  get value() {
    return Object.values(this.fields).reduce((value, field) => {
      return {
        ...value,
        [field.name]: field.value
      };
    }, {});
  }

  get isValid() {
    return Object.values(this.fields).reduce((isValid, field) => {
      return field.isValid && isValid;
    }, true);
  }

  get validationMessages() {
    return Object.values(this.fields).reduce((messages, field) => {
      if (!isPresent(field.validationMessages)) {
        return messages;
      }

      return {
        ...messages,
        [field.name]: field.validationMessages
      };
    }, {});
  }

  constructor(name = null) {
    this.name = isString(name) ? camelize(name) : null;
    this.fields = {};
    this.watchers = [];
    this.parent = null;
  }

  reset() {
    return Object.values(this.fields).forEach((field) => {
      field.reset();
    });
  }

  didChange(...args) {
    if (
      isPresent(this.parent) &&
      isFunction(this.parent.didChange)
    ) {
      this.parent.didChange(this);
    }

    this.publish('change', ...args);
  }

  on(property, handler) {
    if (!isArray(this.watchers[property])) {
      this.watchers[property] = [];
    }

    this.watchers[property].push(handler);
  }

  publish(property, ...args) {
    if (!isArray(this.watchers[property])) {
      return;
    }

    this.watchers[property].forEach((handler) => {
      handler.apply(this, args);
    });
  }

  add(control) {
    if (isSamePath(this.path, control.path.slice(0, -1))) {
      control.parent = this;
      this.fields[control.name] = control;

      this.addFieldGetter(control.name);
    }
    else {
      const name = control.path.slice(this.path.length, 1)[0];
      this.ensureGroup(name);
      this.fields[name].add(control);
    }
  }

  addFieldGetter(fieldName) {
    Object.defineProperty(this, `$${fieldName}`, {
      get: () => {
        return this.fields[fieldName];
      }
    });
  }

  ensureGroup(groupName) {
    if (isPresent(this.fields[groupName])) {
      return;
    }

    this.fields[groupName] = new Group(groupName);
    this.fields[groupName].parent = this;
    this.addFieldGetter(groupName);
  }
}
