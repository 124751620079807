import Api from '$public/api';
import Form from '$public/components/form';

export default {
  initialize(el) {
    const form = Form.initialize(el);

    form.on('submit', () => {
      form.isLoading = true;

      const token = window.location.search
        .match(/token=[^&]+/)[0]
        .split('=')[1];

      Api.post('/reset-password', { token }, form.value)
        .then(() => {
          window.location = '/';
        })
        .catch((error) => {
          form.showAlert({
            type: 'error',
            message: 'Something went wrong. Please try again'
          });
        })
        .finally(() => {
          form.isLoading = false;
        });
    });
  }
};
