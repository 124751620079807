import Api from '$public/api';
import Form from '$public/components/form';

export default {
  initialize(el) {
    const form = Form.initialize(el);

    form.on('submit', () => {
      form.isLoading = true;
      Api.post('/forgot-password', form.value)
        .then(() => {
          form.showAlert({
            type: 'info',
            message: 'Check your email!'
          });
          form.reset();
        })
        .catch(() => {
          form.showAlert({
            type: 'error',
            message: 'Something went wrong. Please try again'
          });
        })
        .finally(() => {
          form.isLoading = false;
        });
    });
  }
};
