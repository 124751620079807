import { isPresent, isString, isArray } from '@tight/is-type';
import coalesce from '$coalesce';

import Icon from '../icon';

export const renderAlert = ({ type, message }) => {
  type = coalesce(type, 'info');

  const alert = document.createElement('div');
  alert.classList.add('alert');
  alert.classList.add(`is-${type}`);

  switch (type) {
    case 'error':
      alert.appendChild(Icon.render('warning.circle'));
      break;
    case 'success':
      alert.appendChild(Icon.render('checkmark.circle'));
      break;
    default:
      alert.appendChild(Icon.render('info.circle'));
      break;
  }

  if (isString(message)) {
    alert.appendChild(p(message));
  }
  else if (isArray(message) && message.length === 1) {
    alert.appendChild(p(message[0]));
  }
  else if (isArray(message)) {
    alert.appendChild(p('A few things needs to be fixed:'));
    alert.appendChild(ul(message));
  }
  return alert;
};

const ul = (items) => {
  return items.reduce((list, item) => {
    const el = document.createElement('li');
    el.innerText = item;

    list.appendChild(el);

    return list;
  }, document.createElement('ul'));
};

const p = (text) => {
  const el = document.createElement('p');
  el.innerText = text;

  return el;
};
