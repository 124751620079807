import { isPresent, isArray } from '@tight/is-type';

const globalPos = (clientX, clientY) =>  {
  return {
    x: window.scrollX + clientX,
    y: window.scrollY + clientY
  };
};

const isBetween = (value, min, max) => {
  return value >= min && value <= max;
};

const START_EVENTS = [
  'mousedown',
  'touchstart'
];

const END_EVENTS = [
  'mouseup',
  'touchend',
  'touchcancel'
];

const DRAG_EVENTS = [
  'mousemove',
  'touchmove'
];

export default class DragHandler {
  get isEnabled() {
    return isPresent(this._isEnabled) ? this._isEnabled : true;
  }

  set isEnabled(value) {
    this._isEnabled = value;
  }

  get isDragging() {
    return !!this._isDragging;
  }

  set isDragging(value) {
    const oldValue = this.isDragging;
    this._isDragging = value;

    if (value === oldValue) {
      return;
    }
    else if (value === true) {
      this.startTime = window.performance.now();
      this.callSubscribers('start');
      this.addDragListeners();
    }
    else {
      this.callSubscribers(
        'end',
        window.performance.now() - this.startTime,
        (this.startX - this.lastX) / this.el.getBoundingClientRect().width
      );
      this.startX = 0;
      this.lastX = 0;
      this.startTime = null;
      this.removeDragListeners();
    }
  }

  constructor(el) {
    this.el = el;
    this.dragListeners = {};
    this.subscribers = {};

    this.attach();
  }

  subscribe(event, handler) {
    if (!isArray(this.subscribers[event])) {
      this.subscribers[event] = [];
    }

    this.subscribers[event].push(handler);
  }

  callSubscribers(event, ...args) {
    if (!isArray(this.subscribers[event])) {
      return;
    }

    this.subscribers[event].forEach((handler) => {
      handler.apply(null, args);
    });
  }

  attach() {
    START_EVENTS.forEach((event) => {
      window.addEventListener(event, (e) => {
        if (this.isEnabled && this.isInside(e)) {
          e.preventDefault();
          this.startX = e.pageX;
          this.lastX = this.startX;
          this.isDragging = true;
        }
      });
    });

    END_EVENTS.forEach((event) => {
      window.addEventListener(event, (e) => {
        if (this.isDragging) {
          e.preventDefault();
          this.isDragging = false;
        }
      });
    });
  }

  onDrag(e) {
    e.preventDefault();
    e.stopPropagation();

    this.callSubscribers(
      'drag',
      (this.lastX - e.pageX) / this.el.getBoundingClientRect().width,
      (this.startX - e.pageX) / this.el.getBoundingClientRect().width
    );

    this.lastX = e.pageX;
  }

  addDragListeners() {
    DRAG_EVENTS.forEach((name) => {
      this.dragListeners[name] = (e) => {
        this.onDrag(e);
      };
      window.addEventListener(name, this.dragListeners[name]);
    });
  }

  removeDragListeners() {
    Object.keys(this.dragListeners).forEach((name) => {
      window.removeEventListener(name, this.dragListeners[name]);
    });
    this.dragListeners = {};
  }

  isInside(e) {
    const eventPos = {
      x: e.pageX,
      y: e.pageY
    };

    const rect = this.el.getBoundingClientRect();
    const topLeft = globalPos(rect.x, rect.top);
    const bottomRight = {
      x: topLeft.x + rect.width,
      y: topLeft.y + rect.height
    };

    return isBetween(eventPos.x, topLeft.x, bottomRight.x) &&
      isBetween(eventPos.y, topLeft.y, bottomRight.y);
  }
}
