import axios from 'axios';
import { isObject, isString, isPresent } from '@tight/is-type';
import { camelizeKeys } from '@tight/pistons-params';

import RequestLog from './request_log';
import preparePayload from './prepare_payload';

class ApiClient {
  constructor(options = {}) {
    this.basePath = isString(options.basePath) ? options.basePath : '/';
  }

  path(path) {
    return `/${this.basePath}/${path}`.replace(/\/{2,}/g, '/');
  }

  get(path, params = {}) {
    return this.request('GET', path, {
      params
    });
  }

  post(path, params = {}, data = null) {
    if (data === null) {
      data = params;
      params = {};
    }
    return this.request('POST', path, {
      params,
      data
    });
  }

  put(path, params = {}, data = null) {
    if (data === null) {
      data = params;
      params = {};
    }
    return this.request('PUT', path, {
      params,
      data
    });
  }

  delete(path, params = {}, data = null) {
    if (data === null) {
      data = params;
      params = {};
    }
    return this.request('DELETE', path, {
      params,
      data
    });
  }

  request(method, path, options = {}) {
    path = this.path(path);
    const log = RequestLog.start(method, path, options.params);

    if (isPresent(options.data)) {
      options.data = preparePayload(options.data);
    }

    const config = {
      ...options,
      url: path,
      method: method,
      headers: {}
    };

    return axios.request(config).then((response) => {
      if (isObject(response.data)) {
        response.data = camelizeKeys(response.data);
      }

      return response;
    }).catch((error) => {
      if (isObject(error.response) && isObject(error.response.data)) {
        error.response.data = camelizeKeys(error.response.data);
      }

      throw error;
    }).finally(() => {
      log.end();
    });
  }
}

export default ApiClient;
