import { isPresent } from '@tight/is-type';
import makeOptions from '@tight/pistons-options';
import walk from '@tight/pistons-walk';

import Easing from '$easing';

class Transition {
  get distance() {
    return this.to - this.from;
  }

  get duration() {
    return this.options.duration;
  }

  get progress() {
    return Math.min(1, this.elapsed / this.duration);
  }

  get elapsed() {
    if (!isPresent(this.startTimestamp)) {
      return 0;
    }
    if (!isPresent(this.previousTimestamp)) {
      return 0;
    }

    return this.previousTimestamp - this.startTimestamp;
  }

  get current() {
    return this.from + this.distance * this.easingFunction(this.progress);
  }

  get isDone() {
    return this.progress >= 1;
  }

  get easingFunction() {
    if (!isPresent(this.options.easingFunction)) {
      return Easing.Cubic.easeInOut;
    }
    return walk(Easing, this.options.easingFunction);
  }

  constructor(from, to, options) {
    this.from = from;
    this.to = to;
    this.options = makeOptions({
      duration: 750,
      easingFunction: 'Cubic.easeInOut'
    }, options);

    this.progressHandlers = [];
    this.completionHandlers = [];
  }


  onProgress(callback) {
    this.progressHandlers.push(callback);
  }

  completion(callback) {
    this.completionHandlers.push(callback);
  }

  step() {
    this.callHandlers(
      this.progressHandlers,
      this.current
    );

    if (this.isDone) {
      this.callHandlers(this.completionHandlers);
    }
    else {
      this.requestStep();
    }
  }

  requestStep() {
    window.requestAnimationFrame((timestamp) => {
      if (!isPresent(this.startTimestamp)) {
        this.startTimestamp = timestamp;
      }

      this.previousTimestamp = timestamp;
      this.step();
    });
  }

  start() {
    this.step();
  }

  callHandlers(handlers, ...args) {
    handlers.forEach((handler) => {
      handler.apply(null, args);
    });
  }
}

export default (from, to, options) => {
  const transition = new Transition(from, to, options);
  transition.start();

  const handler = {
    progress(callback) {
      transition.onProgress(callback);
      return handler;
    },
    then(callback) {
      transition.completion(callback);
      return handler;
    }
  };

  return handler;
};
