import { isArray, isFunction, isPresent } from '@tight/is-type';

import coalesce from '$coalesce';

import Button from './button';
import Control from './control';
import Group from './group';

import { renderAlert } from './alert';
import { formatValidationMessages } from './validation_messages';

const CONTROLS_SELECTOR = [
  'input'
].join(', ');

export default class Form {
  get $controls() {
    return Array.prototype.slice.call(
      this.el.querySelectorAll(CONTROLS_SELECTOR)
    );
  }

  get value() {
    return this.fields.value;
  }

  get isValid() {
    return this.fields.isValid;
  }

  get isLoading() {
    return coalesce(this._isLoading, false);
  }

  set isLoading(value) {
    if (value === this.isLoading) {
      return;
    }

    this._isLoading = value;
    if (isPresent(this.submitButton)) {
      this.submitButton.isLoading = value;
    }
  }

  constructor(el) {
    this.el = el;
    this.fields = new Group();
    this.alerts = [];
    this.watchers = [];

    this.configure();
    this.addEventListeners();
    this.createFields();
    this.createButtons();
  }

  static initialize(el, callback = null) {
    const form = new Form(el);
    if (isFunction(callback)) {
      callback(form);
    }

    return form;
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.validate()) {
      return;
    }

    this.removeAlert('before');
    this.publish('submit', this.value);
  }

  reset() {
    this.validateImmediately = false;
    this.fields.reset();
  }

  validate() {
    const isValid = this.isValid;
    this.submitButton.isEnabled = isValid;
    this.validateImmediately = true;

    if (isValid) {
      this.removeValidationMessages();
    }
    else {
      this.addValidationMessages();
    }

    return isValid;
  }

  addValidationMessages() {
    this.removeValidationMessages();
    this.showAlert({
      type: 'error',
      placement: 'after',
      message: formatValidationMessages(this.fields.validationMessages)
    });
  }

  removeValidationMessages() {
    this.removeAlert('after');
  }

  showAlert(options = {}) {
    const {
      type = null,
      placement = 'before',
      message = null
    } = options;

    if (!isPresent(message)) {
      console.warn('message is empty');
    }

    return this.insertAlert(renderAlert({ type, message }), { placement });
  }

  insertAlert(alert, options = {}) {
    const {
      placement = 'before'
    } = options;

    this.removeAlert(placement);
    if (placement === 'before') {
      this.el.parentNode.insertBefore(alert, this.el);
    }
    else if (placement === 'after') {
      const siblings = Array.prototype.slice.call(this.el.parentNode.children);
      const index = siblings.findIndex((sibling) => {
        return this.el.isSameNode(sibling);
      });


      if (index === siblings.length - 1) {
        this.el.parentNode.appendChild(alert);
      }
      else {
        this.el.parentNode.insertBefore(alert, siblings[index + 1]);
      }
    }

    this.setAlert(placement, alert);
    return alert;
  }

  removeAlert(placement) {
    if (!isPresent(this.alerts[placement])) {
      return;
    }

    this.alerts[placement].parentNode.removeChild(this.alerts[placement]);
    this.alerts[placement] = null;
  }

  setAlert(placement, alert) {
    this.removeAlert(placement);
    this.alerts[placement] = alert;
  }

  on(property, handler) {
    if (!isArray(this.watchers[property])) {
      this.watchers[property] = [];
    }

    this.watchers[property].push(handler);
  }

  publish(property, ...args) {
    if (!isArray(this.watchers[property])) {
      return;
    }

    this.watchers[property].forEach((handler) => {
      handler.apply(this, args);
    });
  }

  createFields() {
    this.$controls.forEach((el) => {
      this.createField(el);
    });
  }

  createField(el) {
    const control = new Control(el);
    this.fields.add(control);
  }

  createButtons() {
    this.submitButton = this.buttonFor('button[type=submit]');
  }

  buttonFor(selectors) {
    if (!isPresent(this.el.querySelector(selectors))) {
      return null;
    }

    return new Button(this.el.querySelector(selectors));
  }

  configure() {
    this.validateImmediately = false;

    this.el.toggleAttribute('novalidate', true);
  }

  addEventListeners() {
    this.addSubmitListener();
    this.addChangeListener();
  }

  addSubmitListener() {
    this.el.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submit();
    });
  }

  addChangeListener() {
    this.fields.on('change', () => {
      if (this.validateImmediately) {
        this.validate();
      }
    });
  }
}
